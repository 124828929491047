@use '../abstract' as *

.content
	@include z-margin-inline
	
	display: grid
	grid-template-columns: 4fr 6fr
	
	border-radius: 20px
	overflow: hidden
	
	background-color: white
	
	*
		@include correctTransformGlitch
	
	.map
		
		> *
			height: 100% !important
		
		.container_element
			height: 100%
		
		.map_element
			height: 100%
	
	.right
		padding: 3rem 4rem 2rem
		
		display: flex
		flex-direction: column
		gap: 20px
		
		> .title
			position: relative
			@include z-typo-heading-inter-tight-semibold-40-49
			
			.line
				position: absolute
				top: 40px
				stroke: $color-orange-principal
				width: 16.5ch
			
			h3
				position: relative
				z-index: 1
		
		.contact_information
			display: flex
			flex-direction: row
			align-items: flex-start
			
			gap: 8rem
			
			.icon
				fill: $color-orange-principal
				height: 2rem
			
			.location
				display: flex
				flex-direction: row
				gap: 1rem
				
				@include z-typo-paragraph-inter-tight-medium-25-30
				
				> .text
					display: flex
					flex-direction: column
					gap: 10px
					
					.link
						display: flex
						flex-direction: row
						gap: 15px
						align-items: center
						
						color: $color-orange-principal
						
						@include z-typo-paragraph-inter-tight-semibold-20-24
						
						> .text
							position: relative
							
							&:after
								content: ''
								position: absolute
								left: 0
								right: 100%
								bottom: -1px
								
								background-color: $color-orange-principal
								height: 1px
								
								transition: right .3s ease
						
						svg
							transition: transform .3s ease
						
						&:hover
							> .text
								&:after
									right: 0
							
							svg
								transform: translateX(.5rem)
			
			.phone_number
				display: flex
				flex-direction: row
				gap: 15px
				align-items: center
				
				@include z-typo-paragraph-inter-tight-medium-25-30
				
				transition: color .3s ease, scale .3s ease
				
				.icon
					.ring
						opacity: 0
						transition: opacity .3s ease
				
				&:hover
					color: $color-orange-principal
					scale: 1.1
					
					.icon
						.ring
							opacity: 1
		
		form
			margin-top: 20px
			display: flex
			flex-direction: column
			gap: 10px
			
			.inputs
				display: grid
				grid-template-columns: repeat(2, 1fr)
				gap: 20px
				
				label
					position: relative
					
					input, textarea
						width: 100%
						
						padding: 1rem 2rem
						background-color: $color-blanc-casse
						
						border-radius: 10px
						border: none
						
						font-family: inherit
						font-size: inherit
						
						box-shadow: inset 0 0 0 0 $color-bleu-principal
						
						transition: box-shadow .3s ease
						
						~ .label
							opacity: 0
							transition: opacity .3s ease
							
							position: absolute
							bottom: 100%
							left: 1rem
							
							font-family: "Inter Tight Medium", sans-serif
							font-size: 12px
						
						&:placeholder-shown
							font-family: inherit
							font-size: inherit
							color: rgba($color-bleu-principal, .5)
						
						&:hover
							box-shadow: inset 0 0 0 3px $color-bleu-principal
						
						&:focus, &:not(:placeholder-shown)
							outline: none
							
							~ .label
								opacity: .4
					
					.error
						display: none
						
						position: absolute
						right: 1rem
						top: 50%
						transform: translateY(-50%)
						
						flex-direction: row
						gap: 10px
						align-items: center
						
						font-size: 12px
					
					&.error
						color: red
						
						input, textarea
							color: red
							box-shadow: inset 0 0 0 3px red
						
						.error
							display: flex
				
				.interested_by
					grid-column: 1 / span 2
					
					display: flex
					flex-direction: column
					gap: 20px
					
					margin-bottom: 10px
					
					width: 100%
					
					.choices
						display: flex
						flex-direction: row
						gap: 20px
						
						width: 100%
						
						.swiper
							width: 100%
							overflow: visible
							
							.swiper_slide
								.content
									margin: 0
								
								&:nth-child(1)
									.logo
										fill: $color-bleu-principal
									
									label
										.content
											box-shadow: inset 0 0 0 0 $color-bleu-principal
										
										&:hover
											.content
												box-shadow: inset 0 0 0 4px $color-bleu-principal
										
										input:checked ~ .content
											background-color: $color-bleu-principal
											
											.logo
												fill: white
								
								&:nth-child(2)
									.logo
										fill: $color-turquoise-audit
									
									label
										.content
											box-shadow: inset 0 0 0 0 $color-turquoise-audit
										
										&:hover
											.content
												box-shadow: inset 0 0 0 4px $color-turquoise-audit
										
										input:checked ~ .content
											background-color: $color-turquoise-audit
											
											.logo
												fill: white
								
								&:nth-child(3)
									.logo
										fill: $color-bordeaux-conseil
									
									label
										.content
											box-shadow: inset 0 0 0 0 $color-bordeaux-conseil
										
										&:hover
											.content
												box-shadow: inset 0 0 0 4px $color-bordeaux-conseil
										
										input:checked ~ .content
											background-color: $color-bordeaux-conseil
											
											.logo
												fill: white
								
								&:nth-child(4)
									.logo
										fill: $color-jaune-social
									
									label
										.content
											box-shadow: inset 0 0 0 0 $color-jaune-social
										
										&:hover
											.content
												box-shadow: inset 0 0 0 4px $color-jaune-social
										
										input:checked ~ .content
											background-color: $color-jaune-social
											
											.logo
												fill: white
						
						label
							position: relative
							
							display: flex
							justify-content: center
							align-items: center
							
							border-radius: 10px
							
							height: 100%
							
							input
								z-index: 10
								position: absolute
								inset: 0
								opacity: 0
								
								width: 100%
								height: 100%
								cursor: pointer
							
							> .content
								padding: 12% 15% 10%
								background-color: $color-blanc-casse
								height: 100%
								width: 100%
								
								border-radius: 10px
								
								display: flex
								justify-content: center
								align-items: flex-start
								
								transition: box-shadow .3s ease
								
								.logo
									width: 100%
									height: fit-content
									overflow: visible
				
				.name
					grid-column: 1 / span 2
				
				.mail
				
				.phone_number
				
				.message
					grid-column: 1 / span 2
			
			.legend
				font-size: 12px
				opacity: .35
			
			.send_button
				margin-top: -10px
	
	@media(max-width: 1500px)
		.right
			.contact_information
				.location, .phone_number
					@include z-typo-paragraph-inter-tight-medium-20-24
				
				.location
					> .text
						.link
							@include z-typo-paragraph-inter-tight-medium-18-21
	
	//@include zi-media
	@media(max-width: 1200px)
		display: flex
		flex-direction: column
		
		.map
			display: none
		
		.right
			gap: 25px
			padding: 2rem
			
			> .title
				@include z-typo-paragraph-inter-tight-semibold-20-24
				margin-bottom: 5px
				
				.line
					top: 20px
			
			.contact_information
				display: flex
				flex-direction: column
				gap: 20px
				
				.icon
					height: 18px
					width: auto
				
				.location, .phone_number
					width: 100%
					@include z-typo-paragraph-inter-tight-medium-18-21
					gap: 10px
				
				.location
					.text
						gap: 5px
			
			form
				.inputs
					display: flex
					flex-direction: column
					gap: 15px
					
					.interested_by
						gap: 10px
						margin-bottom: 5px
						
						.title
							font-size: 15px
						
						.choices
							gap: 10px
							position: relative
							
							&:after
								content: ''
								position: absolute
								top: 0
								bottom: 0
								left: 100%
								right: -2rem
								z-index: 1
								background: linear-gradient(to right, transparent, white 50%)
								pointer-events: none
							
							label
								.content
									padding: 10px 18px
									
									.logo
										max-width: 5rem
				
				.send_button
					margin-top: 0
