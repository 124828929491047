@use '../abstract' as *

.send_button
	border: none
	align-self: flex-end
	justify-self: flex-end
	
	display: flex
	flex-direction: row
	gap: 10px
	align-items: center
	padding: 20px 35px
	
	@include z-typo-paragraph-inter-tight-semibold-20-24
	
	background-color: $color-bleu-principal
	color: white
	border-radius: 10px
	
	cursor: pointer
	
	transition: background .3s ease
	
	&.sent
		background-color: $color-turquoise-audit
	
	&:disabled
		cursor: not-allowed
	
	.icon
		fill: white
		height: 1.5rem
		
		.middle_dot
			transition: transform .3s ease
	
	&:not(.sent)
		&:hover
			background-color: #2D3243
			
			.middle_dot
				transform: translateY(-1px)
	
	@include zi-media
		width: 100%
		justify-content: center
		padding: 15px 30px
		
		.icon
			display: none
